export const environment = {
  production: false,
  useApi: true,
  baseUri: 'https://api.vibes2-staging-data.apps4.icldig.icl-group.com/',
  dataApiEndpoint: 'getPaperChart',
  stocksAPIUrl: 'globes-api/getAllPapers',
  histogramStockAPIUrl: `globes-api/getPaperChart?paperid={{paperId}}`,
  adalConfig: {
    clientId: 'c04e8a79-97d0-4a6a-9e7d-4d4e9731159c',
    issuer: 'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
    strictDiscoveryDocumentValidation: false,
    responseType: 'id_token token',
    scope: 'c04e8a79-97d0-4a6a-9e7d-4d4e9731159c/.default',
  },
  version: '2.1.3.2',
};
