import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, take, takeUntil } from 'rxjs';
import { IStockChartData, IStocksData } from '../../model/stocks-panel';

import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { StockDataService } from 'src/app/services/stock-data.service';

/**
 * @description
 * This component acts as a panel viwe of stocks information. it's also handel a more detailed view of selected stock in a line graph (Echarts graph).
 */
@Component({
  selector: 'app-stocks-panel',
  templateUrl: './stocks-panel.component.html',
  styleUrls: ['./stocks-panel.component.scss'],
})
export class StocksPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  public stocksData$: Observable<IStocksData[] | null> = new Observable<null>();
  public choosenStock: number = 0;
  public options: EChartsOption = {};
  public mergeOptions: EChartsOption= {};
  public myChart: any;
  public stockDataChart$: Observable<IStockChartData | null> = new Observable<null>();
  public stockDataChartSub: Subscription = new Subscription();
  private choosenStockDayChange: number = 0;
  private _destroyed$ = new Subject();
  private firstStock: IStocksData = {} as IStocksData;

  constructor(private stockDataService: StockDataService) { }

  //FIXME: this handler function fired early and collapses on chart.getWidth() with chart being undefined
  private getWidth(chart: any) {
    try  {
      const width = chart.getWidth();
      return width;
    } catch (err) {
      return 0;
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.stockDataChart$.pipe(takeUntil(this._destroyed$)).subscribe((data: IStockChartData | null) => {
        if (!data) return;

        const upColor = '#00b8d8';
        const downColor = '#ff6f74';
        let xAxisData: number[] = [];

        for (let i = 0; i < data.values.length; i++) {
          xAxisData.push(i);
        }

        this.mergeOptions = {
          xAxis: {
            inverse: true,
            data: xAxisData,
          },
          yAxis: {
            type: 'value',
            min: +data.lowRate * 0.95,
            max: +data.maxRate * 1.05,
          },
          series: [
            {
              data: data.values,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:
                      this.choosenStockDayChange >= 0
                        ? upColor + '50'
                        : downColor + '50',
                  },
                  {
                    offset: 1,
                    color: '#002949',
                  },
                ]),
              },
              lineStyle: {
                color: this.choosenStockDayChange >= 0 ? upColor : downColor,
                width: 2,
              },
            },
          ],
        };

        if (data.values.length > 0) {
          const findHeightOfLastRate =
            ((290 * ((+data.maxRate - data.values[1]) / +data.maxRate)) / 100) * (data.maxRate - data.lowRate);

          this.myChart?.dispatchAction({
            type: 'showTip',
            y: findHeightOfLastRate,
            x: this.getWidth(this.myChart) - 54,
          });
        } else {
          this.myChart?.dispatchAction({ type: 'hideTip' });
        }

        // this.showChartByStock(this.firstStock);
        // this.choosenStock = this.firstStock.stockId;
      });



      // this.stocksData$!!.pipe(takeUntil(this._destroyed$)).subscribe((data) => {
      //   if (data) {
      //     this.showChartByStock(data[0]);

      //     this.choosenStock = data[0].stockId;
      //     // this.createStockChartByStockId(this.choosenStock);
      //   }
      // });
    });
  }

  public ngOnInit(): void {
    this.stockDataService.getStocks();
    this.stocksData$ = this.stockDataService.normalizedStocksData$;
    this.stockDataChart$ = this.stockDataService.stockChartData$;
    this.stocksData$.pipe(take(2)).subscribe((data) => {
        if (data) {
          this.showChartByStock(data[0]);
        }
      });
    this.options = {
      tooltip: {
        backgroundColor: '#002949',
        borderColor: '#1A4F69',
        borderWidth: 2,
        borderRadius: 6,
        padding: 0,
        extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0.0); z-index: 0;',
        trigger: 'axis',
        position: function (pos) {
          return [pos[0] - 35, pos[1] - 46];
        },
        formatter:
          '<div style="padding: 5px;" class="tooltip-stock-container"><div class="triangle"></div><span>{c}</span></div>',
        textStyle: {
          fontSize: 14,
          color: '#CFDADB',
          fontFamily: 'DemoLight',
        },
        axisPointer: {
          lineStyle: {
            opacity: 0,
          },
        },
      },
      xAxis: [
        {
          show: false,
          data: [],
        },
      ],
      yAxis: [
        {
          scale: true,
          type: 'value',
          splitNumber: 3,
          axisLabel: {
            showMinLabel: false,
          },
          axisLine: {
            lineStyle: {
              color: '#40697f',
            },
          },
          splitLine: {
            lineStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: '#002949',
                },
                {
                  offset: 0.5,
                  color: '#025579',
                },
                {
                  offset: 1,
                  color: '#002949',
                },
              ]),
            },
          },
          position: 'right',
        },
      ],
      grid: {
        left: 10,
        top: 10,
        right: 50,
        bottom: 0,
      },
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: [],
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#FF6F7450',
              },
              {
                offset: 1,
                color: '#002949',
              },
            ]),
          },
          lineStyle: {
            color: '#FF6F74',
            width: 2,
          },
        },
      ],
    };
  }

  public ngOnDestroy(): void {
    this._destroyed$.next(true);
  }

  public showChartByStock(stock: IStocksData, index?: number): void {
    if(stock && stock.stockId) {
      this.choosenStock = stock.stockId;
      this.choosenStockDayChange = stock.dayChange;
      this.createStockChartByStockId(stock.stockId);
    }
  }

  public createStockChartByStockId(stockId: number): void {
    this.stockDataService.setStockOnChart(stockId);
  }

  public onChartInit(ec: any): void {
    this.myChart = ec;
  }
}
