import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { AuthorizationService } from "../services/authorization.service";
/**
 * This interceptor handles HTTP requests errors.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthorizationService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 || error.status === 403) {
                    this.authService.logout();
                    this.router.navigate(['/']);
                }
                else{
                    console.error(error);
                    this.router.navigate(['error']);
                }
                return throwError(() => error);
            })
        );
    }
}
