import { Component, Input } from '@angular/core';
import { SummaryBatteryItem } from 'src/app/model/vertical-views';

@Component({
  selector: 'app-battery',
  templateUrl: './battery.component.html',
  styleUrls: ['./battery.component.scss'],
})
export class BatteryComponent {

  private _item: SummaryBatteryItem = {} as SummaryBatteryItem;
  @Input() isMainGraph: boolean = false;
  @Input() isTwoColumns: boolean = true;
  @Input() sbuBattery: boolean = false;
  @Input() div: string = '';
  @Input() columnNames: String[] = [];

  @Input()
  set item(newItem: SummaryBatteryItem) {
    if (newItem) {
      this._item = {
        ...newItem,
        negative: newItem.value < 0,
        size: Math.abs(newItem.value) < 100 ? Math.abs(newItem.value) + 1 : 100,
        abs: Math.abs(newItem.value)
      };
    }
  }
  get item(): SummaryBatteryItem {
    return this._item;
  }

  public beforeInit = false; 


  public getLeftOffset(value: number): number {
    if(value > -1){
      if (value < 10){
        return value + 5;
      }
      if (value > 40){
        return 40;
      }
      return value;
    }
    if(Math.abs(value) > 59)
      return 6;
    let left = 60 - Math.abs(value);
    if(Math.abs(value) < 20){
      return left + 2;
    }
    return left;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.beforeInit = true;
    },50);
  }
}
